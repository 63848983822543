<template>
  <div class="progress">
    <div
      :style="'width: ' + progressWidth + '%'"
      class="progress-bar"
      role="progressbar"
      :aria-valuenow="progressWidth"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</template>
<script>
export default {
  name: "ProgressBar",

  props: {
    start: { type: Number, required: false, default: 0 },
    end: { type: Number, required: true },
    position: { type: Number, required: true },
  },

  computed: {
    progressWidth() {
      const duracion = Math.max(0, this.end - this.start);
      const progreso = Math.min((this.position - this.start) / duracion, 1);

      return Math.floor(progreso * 100);
    },
  },
};
</script>
